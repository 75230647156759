import React from "react";
import HighlightSection from "../components/HighlightSection";

export default function AboutText(props) {
	return (
		<div>
			<section className="section about-container">
				<div className="about-top-section">
					<img
						className="about-img"
						src="https://i.imgur.com/RH8yVg9.jpg"
						alt="headshot of Kyle"
					/>
					<div className="about-text-container">
						<h2 className="about-title">About</h2>
						<p className="about-text">
							I'm a Software engineer with a creative background and a passion for
							building visually captivating applications using technologies such
							as React, Node.js, JavaScript, Ruby, Ruby on Rails, HTML, CSS,
							Sass, Express, MongoDB/Mongoose, PostgreSQL and
							Expo. With years of experience in client relations, I have
							developed excellent communication and creative problem solving
							skills. I excel at working with clients to understand their needs and
							the logistics needed to meet them.
						</p>
					</div>
				</div>
				<HighlightSection />
			</section>
		</div>
	);
}
