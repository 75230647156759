import { data } from "../data";
import Project from "../components/Project";

export default function Projects() {
	return (
		<div className="project-main-container">
			<section className="proj-header">
				<h1 className="proj-title">Projects</h1>
			</section>
			<section className="proj-comp-container">
				{data.map((ele, index) => (
					<Project
						key={index}
						id={index}
						title={ele.title}
						summary={ele.summary}
						tech={ele.tech}
						video={ele.video}
						live={ele.liveUrl}
						front={ele.frontendUrl}
						back={ele.backendUrl}
					/>
				))}
			</section>
			<section className="proj-blurb">
				<p>
					These are a few featured projects. Check out what else I've been up to
					on my
					<span></span>
					<a
						href="https://github.com/KyleLynnRas"
						target="_blank"
						rel="noreferrer"
					>
						GitHub page
					</a>
				</p>
			</section>
		</div>
	);
}
