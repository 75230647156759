import { Card } from "react-bulma-components";
//components
import ProjNav from "./ProjNav";

export default function Project(props) {
	return (
		<Card key={props.index} className="block">
			<Card.Content>
				<img
					src={props.video}
					alt={props.title}
					className="giphy"
					id={`id${props.id}`}
				/>
				<div className="content">
					<h2>{props.title}</h2>
					<p>{props.summary}</p>
				</div>
				<ProjNav back={props.back} front={props.front} live={props.live} />
			</Card.Content>
		</Card>
	);
}
