import React from "react";

export default function Highlight(props) {
	return (
		<div className="highlight-container">
			<h1 className="highlight-icon">{props.icon}</h1>
			<p className="highlight-par">{props.par}</p>
		</div>
	);
}
