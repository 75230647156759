import React from "react";
import AboutText from "../components/AboutText";
import SkillSection from "../components/SkillSection";

export default function About() {
	console.log(
		"~*~*~*~*~*~*~*~*~~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~"
	);
	console.log(
		"~*~*~*~*~*~*~*~*~ Thanks for taking a look at my portfolio. Lets collaborate! ~*~*~*~*~*~*~*~*~"
	);
	console.log(
		"~*~*~*~*~*~*~*~*~~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~*~"
	);
	return (
		<div>
			<header className="header">
				<h1 className="home-title">Kyle Rasmussen</h1>
				<h3 className="home-sub">Software Engineer</h3>
			</header>
			<AboutText />
			<SkillSection />
		</div>
	);
}
