import React from "react";
//icons
import { GrLinkedin, GrGithub } from "react-icons/gr";
import { MdEmail } from "react-icons/md";

export default function Contact() {
	return (
		<div className="contact-container">
			<section className="proj-header">
				<h1 className="contact-title">Contact</h1>
			</section>
			<section className="photo-container">
				<h1 className="contact-blurb">Let's connect and collaborate!</h1>
				<div className="photo-box">
					<img
						src="https://i.imgur.com/iCDBiwA.jpg"
						alt="fire dancer"
						className="contact-img"
					/>
					<p>Photo by Rob Klug</p>
				</div>
			</section>
			<section className="main-link-container">
				<div className="contact-link-container">
					<a
						href="https://github.com/KyleLynnRas"
						target="_blank"
						rel="noreferrer"
					>
						<GrGithub className="contact-icon" />
						<span>GitHub</span>
					</a>

					<a
						href="https://www.linkedin.com/in/kylelynn-ras/"
						target="_blank"
						rel="noreferrer"
					>
						<GrLinkedin className="contact-icon" />
						<span>LinkedIn</span>
					</a>

					<a
						href="mailto:kylelynn.ras@gmail.com"
						target="_blank"
						rel="noreferrer"
					>
						<MdEmail className="contact-icon" />
						<span>kylelynn.ras@gmail.com</span>
					</a>
				</div>
			</section>
		</div>
	);
}
