export const data = [
	{
		title: "TroupeLoop",
		summary:
			"A message board for performance troupes created with HTML5, CSS3, JavaScript, Authentication/Authorization, Express, MongoDB, Mongoose, Session, Bootstrap.",
		video: "https://media.giphy.com/media/qq1LOzoSCaRdGj30n9/giphy.gif",
		liveUrl: "https://unit2projectkr.herokuapp.com/",
		frontendUrl: "https://github.com/KyleLynnRas/TroupeLoop",
	},
	{
		title: "npm start career",
		summary:
			"An app to help bootcamp students stay organized in their job search. Created with JavaScript, SASS, Express, React, Mongoose, MongoDB, JWT, Bulma React Components and the Remotive.io API.",
		video: "https://media.giphy.com/media/mKS5KginlzOzk4ZIiF/giphy.gif",
		liveUrl: "https://npmstartcareer.netlify.app/",
		frontendUrl: "https://github.com/KyleLynnRas/proj3-frontend",
		backendUrl: "https://github.com/KyleLynnRas/proj3-backend",
	},
	{
		title: "Booking Site",
		summary:
			"A booking site to showcase different available performance packages and allow users to request a quote. Created with JavaScript, Ruby, Ruby on Rails, PostgreSQL, SASS, JWT Authentication, React, React Bulma Components.",
		video: "https://media.giphy.com/media/shMHGnLZbwStYvMqfd/giphy.gif",
		liveUrl: "https://peculiarityproductions.netlify.app/",
		frontendUrl: "https://github.com/KyleLynnRas/booking-frontend",
		backendUrl: "https://github.com/KyleLynnRas/booking-api-backend",
	},
	{
		title: "Courterly Report",
		summary:
			"An app to help users find pickleball courts in their area. Created with JavaScript, React Native, Expo, Ruby, Ruby on Rails, React navigation, Expo location, React native maps, PostgreSQL, and TomTom’s api",
		video: "https://media.giphy.com/media/WalHtVkwagteW6dvTe/giphy.gif",
		liveUrl: "",
		frontendUrl: "https://github.com/KyleLynnRas/courts-frontend",
		backendUrl: "https://github.com/KyleLynnRas/courts-app-api",
	},
];
