import Tech from './Tech';
import { FaReact } from 'react-icons/fa';
import {
	DiRuby,
	DiSass,
	DiNodejsSmall,
	DiPostgresql,
	DiHtml5,
	DiGitBranch,
	DiHeroku,
} from 'react-icons/di';
import {
	SiRails,
	SiJavascript,
	SiMongodb,
	SiCss3,
	SiBootstrap,
	SiBulma,
	SiExpo,
} from 'react-icons/si';

export default function SkillSection(props) {
	return (
		<section className='section skills-container'>
			<h2 className='skills-title'>Skills</h2>
			<div className='tech-comp-container'>
				<Tech icon={FaReact} label='React' />
				<Tech icon={SiExpo} label='Expo' />
				<Tech icon={DiRuby} label='Ruby' />
				<Tech icon={SiRails} label='Rails' />
				<Tech icon={DiSass} label='Sass' />
				<Tech icon={SiJavascript} label='JavaScript' />
				<Tech icon={SiMongodb} label='MongoDB' />
				<Tech icon={DiNodejsSmall} label='Node.js' />
				<Tech icon={DiPostgresql} label='PostgreSQL' />
				<Tech icon={DiHtml5} label='HTML5' />
				<Tech icon={SiCss3} label='CSS3' />
				<Tech icon={DiGitBranch} label='Git' />
				<Tech icon={SiBootstrap} label='Bootstrap' />
				<Tech icon={SiBulma} label='Bulma' />
				<Tech icon={DiHeroku} label='Heroku' />
			</div>
		</section>
	);
}
