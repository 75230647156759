import React from "react";
import { Route, Switch } from "react-router-dom";
//pages
import Contact from "../pages/Contact";
import About from "../pages/About";
import Projects from "../pages/Projects";

export default function Main() {
	return (
		<main>
			<Switch>
				<Route exact path="/">
					<About />
				</Route>
				<Route path="/projects">
					<Projects />
				</Route>
				<Route path="/contact">
					<Contact />
				</Route>
			</Switch>
		</main>
	);
}
