import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
	return (
		<nav className='navbar'>
			<Link className='nav-link' to='/'>
				<div>ABOUT</div>
			</Link>
			<Link className='nav-link' to='/projects'>
				<div>PROJECTS</div>
			</Link>
			<Link className='nav-link' to='/contact'>
				<div>CONTACT</div>
			</Link>
			<a
				className='nav-link'
				target='_blank'
				rel='noreferrer'
				href='https://drive.google.com/file/d/185qjHmLOdRQss2Zwbcx5FE2D7722IRum/view?usp=sharing'
			>
				RESUME
			</a>
		</nav>
	);
}
